import moment from 'moment';
import Moralis from 'moralis-v1';
import { useState } from 'react';
import { CloudFunctions } from '@constants/CCloudFunctions';
import { TExcelDistribution } from '@typescript/TExcel';
import { TTokenDistribution } from '@typescript/models/TokenDistribution.model';
import * as XLSX from 'xlsx';
import { ExcelDistributionColumn } from '@constants/CExcel';
import { TProject } from '@typescript/models/Project.model';
import useNotification from './useNotification';

function useExcelDistribution() {
  const { notifyError, notifySuccess } = useNotification();

  const [isLoading, setIsLoading] = useState(false);

  const createExcelDistribution = async (
    distributions: TExcelDistribution[],
  ) => {
    try {
      await Moralis.Cloud.run(
        CloudFunctions.CREATE_EXCEL_DISTRIBUTION,
        distributions,
      );
      notifySuccess('Created excel distributions');
    } catch (error) {
      notifyError('Create excel distribution failed!');
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const excelTimestampToJsTimestamp = (excelTimestamp: number) => {
    const jsTimestamp = Math.round((excelTimestamp - 25569) * 86400);
    return jsTimestamp;
  };

  const validateExcelDistribution = (distribution: TExcelDistribution) => {
    const checkExists = (property: keyof TExcelDistribution) => {
      if (!distribution[property]) {
        setIsLoading(false);
        throw new Error(`Missing ${property} value in distribution`);
      }
    };

    checkExists('chain');
    checkExists('senderWallet');
    checkExists('receiverWallet');
    checkExists('tokensSent');
    checkExists('symbol');
    checkExists('blockHeight');
    checkExists('timestamp');
    checkExists('hash');
  };

  const handleUploadDistributions = async (excelFile: File) => {
    setIsLoading(true);
    const extension = excelFile.name.split('.')[1];
    if (extension !== 'xlsx' && extension !== 'xls') {
      notifyError('Only Excel files are allowed to be uploaded!');
      setIsLoading(false);
      return;
    }
    const reader = new FileReader();

    reader.onload = async (e) => {
      const bstr = e.target?.result;
      const excel = XLSX.read(bstr, { type: 'binary' });

      const allDistributions: TExcelDistribution[] = [];

      const firstSheet = excel.SheetNames[0];
      const workSheet = excel.Sheets[firstSheet];
      const sheetData = XLSX.utils.sheet_to_json(workSheet, {
        header: 1,
      }) as string[];

      // ? validate excel format type
      const headerChain = sheetData[0]?.[ExcelDistributionColumn.CHAIN];
      if (headerChain?.trim()?.toLowerCase() !== 'chain') {
        setIsLoading(false);
        notifyError('The excel file is not in the correct format type');
        throw Error('The excel file is not in the distribution format type');
      }

      for (let rowIndex = 1; rowIndex < sheetData.length; rowIndex += 1) {
        const row = sheetData[rowIndex];
        const chain = row[ExcelDistributionColumn.CHAIN];
        const senderWallet = row[ExcelDistributionColumn.SENDER_WALLET];
        const receiverWallet = row[ExcelDistributionColumn.RECEIVER_WALLET];
        const tokensSent = +row[ExcelDistributionColumn.TOKENS_SENT] || 0;
        const amountInUsd = +row[ExcelDistributionColumn.AMOUNT_IN_USD] || 0;
        const symbol = row[ExcelDistributionColumn.SYMBOL];
        const tokenAddress = row[ExcelDistributionColumn.TOKEN_ADDRESS];
        const currencyName = row[ExcelDistributionColumn.CURRENCY_NAME];
        const blockHeight = +row[ExcelDistributionColumn.BLOCK_HEIGHT] || 0;
        let timestamp = +row[ExcelDistributionColumn.TIMESTAMP];
        timestamp = excelTimestampToJsTimestamp(timestamp);
        const hash = row[ExcelDistributionColumn.HASH];

        const distribution: TExcelDistribution = {
          chain: chain?.toUpperCase(),
          senderWallet: senderWallet?.toLowerCase().trim(),
          receiverWallet: receiverWallet?.toLowerCase().trim(),
          tokensSent,
          amountInUsd,
          symbol: symbol?.toUpperCase().trim(),
          tokenAddress: tokenAddress?.toLocaleLowerCase().trim(),
          currencyName,
          blockHeight,
          timestamp,
          hash,
        };

        validateExcelDistribution(distribution);

        allDistributions.push(distribution);
      }
      // eslint-disable-next-line no-await-in-loop
      await createExcelDistribution(allDistributions);
    };

    reader.readAsBinaryString(excelFile);
  };

  const handleExportDistributions = (
    distributions: TTokenDistribution[],
    projects: TProject[],
  ) => {
    // ? create the excel file
    const workbook = XLSX.utils.book_new();

    const sheetHeader = [
      'chain',
      'sender_address',
      'receiver_address',
      'sent',
      'amount_usd',
      'currency_symbol',
      'token_address',
      'currency_name',
      'block_height',
      'timestamp',
      'hash',
    ];

    projects.forEach((project) => {
      const projectDistributions = distributions.filter(
        (distribution) => distribution.project.id === project.id,
      );

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const excelSheet: any[] = [sheetHeader];

      projectDistributions.forEach((distribution) => {
        const excelRow = [
          distribution.project.chain,
          distribution.senderWallet,
          distribution.receiverWallet,
          distribution.tokensSent,
          distribution.amountInUsd,
          distribution.symbol,
          distribution.tokenAddress,
          distribution.currencyName,
          distribution.blockHeight,
          moment.unix(distribution.timestamp || 0).format('DD/MM/YYYY HH:mm'),
          distribution.hash,
        ];

        excelSheet.push(excelRow);
      });

      const sheet = XLSX.utils.aoa_to_sheet(excelSheet);
      if (project.name) {
        XLSX.utils.book_append_sheet(
          workbook,
          sheet,
          project.name.toLowerCase(),
        );
      }
    });

    const fileName =
      projects.length === 1
        ? `${projects[0].name}-distributions.xlsx`
        : 'distributions.xlsx';

    XLSX.writeFile(workbook, fileName);
  };

  return {
    isLoading,
    handleUploadDistributions,
    handleExportDistributions,
  };
}

export default useExcelDistribution;
