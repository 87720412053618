import { setShowLoginPopup, ActionFunction } from '@store/slices/connectSlice';
import { useAppDispatch, store } from '@store/store';
import useUtils from '@hooks/useUtils';
import { useMoralis } from 'react-moralis';
import useUser from './useUser';

const useActionOrConnect = () => {
  const { incompatibleChain } = useUtils();
  const { isWeb3Enabled } = useMoralis();

  const dispatch = useAppDispatch();
  const state = store.getState().connect;
  const { currentUser } = useUser();

  const handleActionOrLogin = (action?: ActionFunction) => {
    const hasSkippedNotification = Boolean(
      localStorage.getItem('socialSkipped'),
    );

    if (
      currentUser &&
      isWeb3Enabled &&
      !incompatibleChain &&
      (state.hasSocials || hasSkippedNotification)
    ) {
      if (action) {
        action();
      }
    } else {
      dispatch(setShowLoginPopup(true));
    }
  };

  return handleActionOrLogin;
};
export default useActionOrConnect;
