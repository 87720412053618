import Moralis from 'moralis-v1';
import { useChain, useWeb3Transfer } from 'react-moralis';
import { ServerNetwork } from '@utils/functions';
import { TTxResponse } from '@typescript/models/TradeTransaction.model';
import {
  useCreateEthInvestmentMutation,
  useLazyCheckEthInvestmentQuery,
} from '@store/api/ethInvestmentApi';
import { ChainId } from '@constants/CChains';
import { LegionAddress } from '@constants/CLegionContract';
import { ParseError } from '@utils/errors';
import useUtils from './useUtils';
import useNotification from './useNotification';
import useUser from './useUser';

interface ETHStableTransfer {
  amount: number;
  to: string;
}

interface EthInvestmentTransfer {
  to: string;
  projectId: string;
  acceptedTerms: boolean;
  investment: number;
}

export default function useEthTransfer() {
  const { fetch } = useWeb3Transfer();

  const { serverNetwork } = useUtils();

  const { chainId } = useChain();

  const { currentUser } = useUser();

  const { notifySuccess, notifyError } = useNotification();

  const [createEthInvestment] = useCreateEthInvestmentMutation();

  const [checkEthInvestment] = useLazyCheckEthInvestmentQuery();

  const ethLegionTransfer = async ({ amount, to }: ETHStableTransfer) => {
    if (!serverNetwork) {
      throw Error('No server network found to perform the eth stable transfer');
    }

    if (!currentUser) {
      throw Error('No user found to perform the eth stable transfer');
    }

    if (chainId !== ChainId.ETH && serverNetwork === ServerNetwork.BSC) {
      throw Error('No user found to perform the eth stable transfer');
    }

    if (
      chainId !== ChainId.SEPOLIA &&
      serverNetwork === ServerNetwork.BSC_TESTNET
    ) {
      throw Error('No user found to perform the eth TESTNET stable transfer');
    }

    const legionTokenAddress = LegionAddress[serverNetwork];

    const txResponse = await fetch({
      params: {
        amount: Moralis.Units.Token(amount, 18),
        receiver: to,
        type: 'erc20',
        contractAddress: legionTokenAddress,
      },
      throwOnError: true,
    });

    notifySuccess('Transaction created');
    return txResponse as unknown as TTxResponse;
  };

  const ethInvestmentTransfer = async ({
    acceptedTerms,
    investment,
    projectId,
    to,
  }: EthInvestmentTransfer) => {
    try {
      await checkEthInvestment({ investment, projectId }).unwrap();

      const txResponse = await ethLegionTransfer({
        amount: investment,
        to,
      });

      await createEthInvestment({
        acceptedTerms,
        hash: txResponse.hash,
        investment,
        projectId,
      }).unwrap();

      return txResponse;
    } catch (e) {
      const error = e as ParseError;
      if (error?.message) {
        notifyError(error?.message);
        throw Error(error?.message);
      }
      notifyError('Something went wrong..');
      throw error;
    }
  };

  return {
    ethLegionTransfer,
    ethInvestmentTransfer,
    createEthInvestment,
  };
}
