import { useTheme } from '@mui/material';

const useStyles = () => {
  const { palette } = useTheme();
  const border1pxSecondaryLight = `1px solid ${palette.secondary.light}`;
  const border1pxPrimaryLight = `1px solid ${palette.primary.light}`;

  return {
    border1pxSecondaryLight,
    border1pxPrimaryLight,
  };
};
export default useStyles;
