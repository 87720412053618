import { calculate, formatNr } from '@utils/functions';
import {
  useCreateExcelProjectMutation,
  useCreateProjectMutation,
  useDeleteAllProjectsMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} from '@store/api/projectApi';
import { TProject } from '@typescript/models/Project.model';

const useProject = () => {
  const [updateProject] = useUpdateProjectMutation();
  const [deleteAllProjects] = useDeleteAllProjectsMutation();
  const [deleteProject] = useDeleteProjectMutation();
  const [createProject] = useCreateProjectMutation();
  const [createExcelProject] = useCreateExcelProjectMutation();

  const getProjectProgress = (project: TProject) => {
    let projectPercentage = 100;
    if (project.totalUnlockedTokens) {
      projectPercentage = calculate(100, '*', project.totalUnlockedTokens);
    }
    if (project.totalTokens) {
      projectPercentage = calculate(
        projectPercentage,
        '/',
        project.totalTokens,
      );
    }
    return +formatNr(projectPercentage);
  };

  const getProjectProgressInvestment = (project: TProject) => {
    let projectPercentage = 100;
    if (project.investmentAmount) {
      projectPercentage = calculate(100, '*', project.investmentAmount);
    }
    if (project.fundraisingTarget) {
      projectPercentage = calculate(
        projectPercentage,
        '/',
        project.fundraisingTarget,
      );
    }
    return +formatNr(projectPercentage);
  };

  return {
    createProject,
    deleteProject,
    createExcelProject,
    deleteAllProjects,
    updateProject,
    getProjectProgress,
    getProjectProgressInvestment,
  };
};

export default useProject;
