import { useNewMoralisObject } from 'react-moralis';
import { Collections } from '@constants/CCollections';
import { useUpdateProjectDetailsMutation } from '@store/api/projectDetailApi';
import { CreateProjectDetailsDTO } from '@typescript/dtos/project/createProject.dto';
import useNotification from './useNotification';

const useProjectDetails = () => {
  const { notifyError, notifySuccess } = useNotification();

  const { save: SaveProjectDetails } = useNewMoralisObject(
    Collections.PROJECTS_DETAILS,
  );

  const [updateProjectDetails] = useUpdateProjectDetailsMutation();

  const createProjectDetails = async (data: CreateProjectDetailsDTO) =>
    SaveProjectDetails(data, {
      onSuccess: (newData) => {
        notifySuccess('Project Details Created!');
        return newData;
      },
      onError: (error) => {
        notifyError('Project Details Creation Failed!');
        throw error;
      },
    });

  return {
    createProjectDetails,
    updateProjectDetails,
  };
};

export default useProjectDetails;
