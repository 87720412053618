import ReactDOM from 'react-dom/client';
import './index.css';
import { SnackbarProvider } from 'notistack';
import { MoralisProvider } from 'react-moralis';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import MoralisInitialization from '@components/MoralisInitialization';
import { StrictMode, useEffect } from 'react';
import MuiInitialization from '@components/MuiInitialization';
import { ENV } from '@utils/myEnv';
import ClassSubscriptions from '@contexts/ClassSubscriptions';
import { Provider } from 'react-redux';
import { store } from '@store/store';
import * as Sentry from '@sentry/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ENVIRONMENT } from '@typescript/Environment.enum';
import App from './App';
import pjson from '../package.json';
import './utils/console';

if (
  process.env.REACT_APP_PROJECT_ENVIRONMENT === ENVIRONMENT.STAGING ||
  process.env.REACT_APP_PROJECT_ENVIRONMENT === ENVIRONMENT.PRODUCTION
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
    ],
    tracePropagationTargets: [
      /^https:\/\/moralis\.legion\.ventures\/server/,
      /^https:\/\/staging\.moralis\.legion\.ventures\/server/,
    ],
    environment: process.env.REACT_APP_PROJECT_ENVIRONMENT,
    release: `${process.env.REACT_APP_SENTRY_PROJECT}@${pjson.version}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.01,
  });
}

function MetaComponent() {
  return (
    <Helmet encodeSpecialCharacters>
      {process.env.REACT_APP_PROJECT_ENVIRONMENT === 'production' && (
        <script type="text/javascript">
          {`(function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "lr925l99op");
      `}
        </script>
      )}
    </Helmet>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <MoralisProvider serverUrl={ENV.serverUrl} appId={ENV.serverId}>
      <HelmetProvider>
        <BrowserRouter>
          <Provider store={store}>
            <SnackbarProvider autoHideDuration={3000}>
              <MuiInitialization>
                <MoralisInitialization>
                  <ClassSubscriptions>
                    <MetaComponent />
                    <App />
                  </ClassSubscriptions>
                </MoralisInitialization>
              </MuiInitialization>
            </SnackbarProvider>
          </Provider>
        </BrowserRouter>
      </HelmetProvider>
    </MoralisProvider>
  </StrictMode>,
);
