import { CloudFunctions } from '@constants/CCloudFunctions';
import {
  useGetCurrentUserQuery,
  useUpdateUserInfoMutation,
  useUpdateUserMutation,
} from '@store/api/userApi';
import { updateUserZod } from '@typescript/dtos/user/updateUser.dto';
import { updateUserInfoZod } from '@typescript/dtos/user/updateUserInfo.dto';
import { UserInformation } from '@typescript/models/UserInformation.model';
import {
  useMoralis,
  useMoralisCloudFunction,
  useMoralisFile,
} from 'react-moralis';

const useUser = () => {
  const { account } = useMoralis();

  const { saveFile } = useMoralisFile();

  const [updateUser] = useUpdateUserMutation();

  const { data: currentUser, isLoading } = useGetCurrentUserQuery({});

  const sameAccount = !(account && account !== currentUser?.ethAddress);

  const updateUserName = async (username: string) => {
    if (currentUser) {
      await updateUser(
        updateUserZod.parse({ id: currentUser.id, username }),
      ).unwrap();
    }
  };

  const updateImage = async (file?: File) => {
    if (file && currentUser) {
      const fileName = file.name;
      const fileIpfs = await saveFile(fileName, file, { saveIPFS: true });

      await updateUser(
        updateUserZod.parse({ id: currentUser.id, profileImage: fileIpfs }),
      );
    }
  };

  const hightestUserBalance = () => {
    if (currentUser) {
      const { balance } = currentUser;
      const balances = balance.map((coin) => coin.balance);
      return Math.max(...balances);
    }
    return 0;
  };

  const insufficientFunds = (amount: number) => {
    if (amount === 0) {
      throw Error("The amount can't be 0 in insufficientFunds");
    }

    const hightestBalance = hightestUserBalance();
    if (hightestBalance < amount) {
      return true;
    }
    return false;
  };

  const [updateUserInfo] = useUpdateUserInfoMutation();

  const { fetch: runSendEmail } = useMoralisCloudFunction(
    CloudFunctions.SEND_EMAIL,
    {},
    { autoFetch: false },
  );

  const updateEmailVerification = async (
    state: boolean,
    userInformationProp: UserInformation,
  ) => {
    await updateUserInfo(
      updateUserInfoZod.parse({
        ...userInformationProp,
        isEmailVerified: state,
      }),
    ).unwrap();
  };

  const sendEmail = async (email: string, name: string) => {
    await runSendEmail({ throwOnError: true, params: { email, name } });
  };

  const updateEmail = async (email: string, userInfo: UserInformation) => {
    await updateUserInfo(
      updateUserInfoZod.parse({
        ...userInfo,
        isEmailVerified: false,
        email,
      }),
    ).unwrap();
  };

  const verifyEmailCode = async (
    code: number,
  ): Promise<'success' | 'invalid'> => {
    if (!currentUser) {
      throw Error('The user can not be found');
    }
    const { info } = currentUser;
    const userCode = info?.emailVerificationCode;
    if (code === userCode) {
      await updateUserInfo(
        updateUserInfoZod.parse({
          ...info,
          isEmailVerified: true,
          emailVerificationCode: code,
        }),
      ).unwrap();
      return 'success';
    }
    return 'invalid';
  };

  const updateSocial = async (
    discordName: string,
    telegramLink: string,
    userInformationProp: UserInformation,
  ) => {
    await updateUserInfo(
      updateUserInfoZod.parse({
        ...userInformationProp,
        telegramLink,
        discordName,
      }),
    ).unwrap();
  };

  return {
    updateImage,
    updateUserName,
    currentUser,
    updateSocial,
    userCoins: currentUser?.balance,
    hightestUserBalance,
    insufficientFunds,
    sameAccount,
    userInformation: currentUser?.info,
    isAdmin: currentUser?.info?.isAdmin,
    updateEmailVerification,
    updateEmail,
    sendEmail,
    verifyEmailCode,
    isLoading,
  };
};
export default useUser;
