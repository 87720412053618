import { useState } from 'react';
import * as XLSX from 'xlsx';
import { z } from 'zod';
import { formatToNumber } from '@utils/functions';
import {
  RecalculateExcelProjectDto,
  recalculateExcelAllocationZod,
  recalculateExcelProjectZod,
} from '@typescript/dtos/project/updateProject.dto';
import { useRecalculateExcelProjectMutation } from '@store/api/projectApi';
import useNotification from './useNotification';

function useProjectExcelRecalculations() {
  const [recalculateExcelProjectMutation] =
    useRecalculateExcelProjectMutation();
  const { notifyError, notifySuccess } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const recalculateExcelProject = async (excelFile: File) => {
    setIsLoading(true);
    const extension = excelFile.name.split('.')[1];
    if (extension !== 'xlsx' && extension !== 'xls') {
      notifyError('Only Excel files are allowed to be uploaded!');
      setIsLoading(false);
      throw Error('Only Excel files are allowed to be uploaded!');
    }
    const reader = new FileReader();

    const projectData: RecalculateExcelProjectDto = {
      projectName: '',
      allocations: [],
    };

    reader.onload = async (e) => {
      const excel = XLSX.read(e.target?.result);

      const firstSheetName = excel.SheetNames[0];
      const firstSheet = excel.Sheets[firstSheetName];

      const sheetData = XLSX.utils.sheet_to_json(firstSheet, {
        header: 1,
      }) as string[];

      const PROJECT_NAME_COLUMN = 0;
      const WALLET_COLUMN = 1;
      const INVESTMENT_COLUMN = 2;

      // ? We take the first column and first row as the project name;
      const PROJECT_NAME = z
        .string()
        .min(2)
        .parse(sheetData[0][PROJECT_NAME_COLUMN]);

      const allocations = sheetData.map((row) => {
        const unknownAllocationData = {
          wallet: row[WALLET_COLUMN].trim().toLowerCase(),
          investment: formatToNumber(row[INVESTMENT_COLUMN]),
        };
        const allocationData = recalculateExcelAllocationZod.parse(
          unknownAllocationData,
        );
        return allocationData;
      });

      projectData.projectName = PROJECT_NAME;
      projectData.allocations = allocations;
      setIsLoading(false);
      try {
        await recalculateExcelProjectMutation(
          recalculateExcelProjectZod.parse(projectData),
        ).unwrap();
        notifySuccess(`Project ${projectData.projectName} recalculated.`);
      } catch (error) {
        notifyError(error as string);
        throw error;
      }
    };

    reader.readAsArrayBuffer(excelFile);
  };

  return {
    isLoading,
    recalculateExcelProject,
  };
}

export default useProjectExcelRecalculations;
