import Moralis from 'moralis-v1';

import useUtils from '@hooks/useUtils';
import {
  TERC20ApproveFnParams,
  TERC20ApproveOptions,
  TERC20CheckAllowanceFnParams,
  TERC20CheckAllowanceOptions,
  TERC20CheckAllowanceResponse,
} from '@typescript/TERC20Contract';
import { ERC20Contract, ERC20ContractFn } from '@constants/CERC20Contract';
import { TTxResponse } from '@typescript/models/TradeTransaction.model';
import useNotification from './useNotification';

function useERC20() {
  const { notifyInfo } = useNotification();

  const { serverNetwork } = useUtils();

  const approve = async ({ coin, spender, amount }: TERC20ApproveFnParams) => {
    if (!serverNetwork) {
      throw Error('Server network not initialized.');
    }

    const stableAbi = ERC20Contract[coin][serverNetwork].abi;
    const stableAddress = ERC20Contract[coin][serverNetwork].address;

    const options: TERC20ApproveOptions = {
      abi: stableAbi,
      contractAddress: stableAddress,
      functionName: ERC20ContractFn.APPROVE,
      params: {
        spender,
        amount,
      },
    };

    const txResponse = (await Moralis.executeFunction(
      options,
    )) as unknown as TTxResponse;

    notifyInfo('Approving..');

    await txResponse.wait(4);

    return txResponse;
  };

  const checkAllowance = async ({
    coin,
    spender,
    owner,
  }: TERC20CheckAllowanceFnParams) => {
    if (!serverNetwork) {
      throw Error('Server network not initialized.');
    }

    const stableAbi = ERC20Contract[coin][serverNetwork].abi;
    const stableAddress = ERC20Contract[coin][serverNetwork].address;

    const options: TERC20CheckAllowanceOptions = {
      abi: stableAbi,
      contractAddress: stableAddress,
      functionName: ERC20ContractFn.ALLOWANCE,
      params: {
        spender,
        owner,
      },
    };

    const response = (await Moralis.executeFunction(
      options,
    )) as unknown as TERC20CheckAllowanceResponse;

    const allowance = Moralis.Units.FromWei(response._hex, 18);
    return allowance;
  };

  return {
    approve,
    checkAllowance,
  };
}

export default useERC20;
