import { useWeb3Transfer } from 'react-moralis';
import {
  TStabileTransferParameters,
  TTokenTransferParameters,
  TTxResponse,
  TradeType,
} from '@typescript/models/TradeTransaction.model';
import Moralis from 'moralis-v1';
import { Coin } from '@constants/CCoin';
import { TListing } from '@typescript/models/Listing.model';
import { BLOCK_CONFIRMATIONS } from '@constants/CLimits';
import { TOffer } from '@typescript/models/Offer.model';
import { OfferStatus } from '@constants/COffer';
import { ERC20Contract } from '@constants/CERC20Contract';
import { TransferType } from '@constants/CTransfer';
import { idZod } from '@typescript/dtos/shared/id.dto';
import {
  useConfirmTradeTransactionMutation,
  useCreateTransactionMutation,
} from '@store/api/transactionApi';
import { createTransactionZod } from '@typescript/dtos/transaction/createTransaction.dto';
import {
  useConfirmInvestmentTransactionMutation,
  useCreateTransactionInvestmentMutation,
} from '@store/api/transactionInvestmentApi';

import useUtils from './useUtils';
import useListings from './useListings';
import useOffers from './useOffers';
import useOtcTransferContract from './useOtcTransferContract';
import useUser from './useUser';
import useNotification from './useNotification';

const useTransfer = () => {
  const { fetch: makeStableTransfer } = useWeb3Transfer();

  const { updateTransactionInProgress } = useListings();

  const [createTradeTransaction] = useCreateTransactionMutation();

  const { notifySuccess } = useNotification();

  const { serverNetwork, transferType } = useUtils();

  const { transfer: otcContractTransfer } = useOtcTransferContract();

  const [createTransactionInvestment] =
    useCreateTransactionInvestmentMutation();

  const [confirmInvestmentTransaction] =
    useConfirmInvestmentTransactionMutation();

  const [confirmTradeTransaction] = useConfirmTradeTransactionMutation();

  const { currentUser: user } = useUser();

  const { notifyError } = useNotification();

  const { updateStatusOffer } = useOffers();

  const transferFunds = async ({
    coin,
    amount,
    receiverAddress,
    listingId,
    type,
    tokenPrice,
    tokenAmount,
  }: TTokenTransferParameters) => {
    if (!serverNetwork) {
      throw Error('No serverNetwork found to make contract transfer');
    }

    if (!transferType) {
      throw Error('No transferType found to make contract transfer');
    }

    if (transferType === TransferType.OTC_TRANSFER_CONTRACT) {
      const txResponse = await otcContractTransfer({
        amount: amount.toString(),
        coin,
        listingId,
        receiver: receiverAddress,
        type,
        tokenPrice,
        tokenAmount,
      });

      notifySuccess('Transaction created');
      return txResponse as unknown as TTxResponse;
    }

    if (transferType === TransferType.STABLE) {
      const txResponse = await makeStableTransfer({
        params: {
          amount: Moralis.Units.Token(amount, 18),
          receiver: receiverAddress,
          type: 'erc20',
          contractAddress: ERC20Contract[coin][serverNetwork].address,
        },
        throwOnError: true,
      });

      notifySuccess('Transaction created');
      return txResponse as unknown as TTxResponse;
    }

    throw Error('Transaction type unsupported');
  };

  const stableTransferFunds = async ({
    coin,
    amount,
    receiverAddress,
  }: TStabileTransferParameters) => {
    if (!serverNetwork) {
      throw Error('No serverNetwork found to make contract transfer');
    }
    const txResponse = (await makeStableTransfer({
      params: {
        amount: Moralis.Units.Token(amount, 18),
        receiver: receiverAddress,
        type: 'erc20',
        contractAddress: ERC20Contract[coin][serverNetwork].address,
      },
      throwOnError: true,
    })) as unknown as TTxResponse;

    notifySuccess('Transaction created');
    return txResponse;
  };

  const wtsTransfer = async (
    buyCoin: Coin,
    listing: TListing,
    beforeConfirmationFn: (hash: string) => void,
  ) => {
    if (!user) {
      throw Error('No user found for buyWTSListing');
    }
    if (listing.isDeleted) {
      notifyError('The listing you are trying to ts sell deleted');
      throw Error('No listing is deleted');
    }
    try {
      const txResponse = await transferFunds({
        amount: listing.price,
        coin: buyCoin,
        receiverAddress: listing.user.ethAddress,
        listingId: listing.id,
        tokenPrice: listing.tokenPrice,
        type: TradeType.WTS,
        tokenAmount: listing.amount,
      });
      beforeConfirmationFn(txResponse.hash);

      await Promise.all([
        createTradeTransaction(
          createTransactionZod.parse({
            fromId: user.id,
            toId: listing.user.id,
            value: listing.price,
            hash: txResponse.hash,
            coin: buyCoin,
            listingId: listing.id,
            tradeType: 'WTS',
          }),
        ).unwrap(),
        updateTransactionInProgress(idZod.parse({ id: listing.id })).unwrap(),
      ]);

      await txResponse.wait(BLOCK_CONFIRMATIONS);
      await confirmTradeTransaction(txResponse.hash);

      return txResponse;
    } catch (error) {
      notifyError('Buy transaction failed!');
      throw error;
    }
  };

  const wtbTransfer = async (
    listing: TListing,
    beforeConfirmationFn: (hash: string) => void,
  ) => {
    try {
      if (!listing.acceptedBy?.ethAddress) {
        throw Error('No accepted user found for wtbTransfer');
      }

      if (!user) {
        throw Error('No user found for wtbTransfer');
      }

      if (!listing.acceptedCoin) {
        throw Error('No accepted coin found for wtbTransfer');
      }

      if (listing.isDeleted) {
        notifyError('The listing you are trying to buy is deleted');
        throw Error('No listing is deleted ');
      }

      const txResponse = await transferFunds({
        amount: listing.price,
        coin: listing.coins[0],
        receiverAddress: listing.acceptedBy.ethAddress,
        listingId: listing.id,
        tokenPrice: listing.tokenPrice,
        type: TradeType.WTB,
        tokenAmount: listing.amount,
      });
      beforeConfirmationFn(txResponse.hash);

      await Promise.all([
        createTradeTransaction(
          createTransactionZod.parse({
            fromId: user.id,
            toId: listing.acceptedBy.id,
            value: listing.price,
            hash: txResponse.hash,
            coin: listing.coins[0],
            listingId: listing.id,
            tradeType: 'WTB',
          }),
        ).unwrap(),
        updateTransactionInProgress(idZod.parse({ id: listing.id })).unwrap(),
      ]);

      await txResponse.wait(BLOCK_CONFIRMATIONS);
      await confirmTradeTransaction(txResponse.hash);

      return txResponse;
    } catch (error) {
      notifyError('Buy transaction failed!');
      throw error;
    }
  };

  const offerTransfer = async (
    offer: TOffer,
    beforeConfirmationFn: (hash: string) => void,
  ) => {
    if (!user) {
      throw Error('No user found for buyWTSListing');
    }
    const { listing } = offer;

    try {
      const txResponse = await transferFunds({
        amount: offer.offeredPrice,
        coin: offer.offerCoin,
        receiverAddress: listing.user.ethAddress,
        listingId: offer.listing.id,
        type: TradeType.OFFER,
        tokenPrice: offer.offeredTokenPrice,
        tokenAmount: listing.amount,
      });
      beforeConfirmationFn(txResponse.hash);
      await Promise.all([
        createTradeTransaction(
          createTransactionZod.parse({
            fromId: user.id,
            toId: listing.user.id,
            value: offer.offeredPrice,
            hash: txResponse.hash,
            coin: offer.offerCoin,
            listingId: listing.id,
            tradeType: 'OFFER',
          }),
        ).unwrap(),
        updateTransactionInProgress(
          idZod.parse({ id: offer.listing.id }),
        ).unwrap(),
        updateStatusOffer(offer, OfferStatus.PERFORMED),
      ]);

      await txResponse.wait(BLOCK_CONFIRMATIONS);
      await confirmTradeTransaction(txResponse.hash);

      return txResponse;
    } catch (error) {
      notifyError('Offer transaction failed!');
      throw error;
    }
  };

  return {
    createTransactionInvestment,
    createTradeTransaction,
    confirmInvestmentTransaction,
    stableTransferFunds,
    wtsTransfer,
    wtbTransfer,
    offerTransfer,
  };
};

export default useTransfer;
