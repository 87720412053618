import { TOffer } from '@typescript/models/Offer.model';
import { TListing } from '@typescript/models/Listing.model';
import { OfferStatus } from '@constants/COffer';
import {
  useCreateOfferMutation,
  useDeleteOfferMutation,
  useUpdateOfferMutation,
  useUpdateOfferStatusMutation,
} from '@store/api/offerApi';
import {
  UpdateOfferDTO,
  setStatusOfferZod,
  updateOfferZod,
} from '@typescript/dtos/offer/updateOffer.dto';
import { idZod } from '@typescript/dtos/shared/id.dto';

const useOffers = () => {
  const [updateOffer] = useUpdateOfferMutation();

  const [deleteOfferMutation] = useDeleteOfferMutation();

  const [createOffer] = useCreateOfferMutation();

  const [updateOfferStatus] = useUpdateOfferStatusMutation();

  const updateStatusOffer = async (offer: TOffer, status: OfferStatus) => {
    await updateOfferStatus(
      setStatusOfferZod.parse({
        offerStatus: status,
        id: offer.id,
      }),
    ).unwrap();
  };

  const modifyOffer = async (values: UpdateOfferDTO) => {
    await updateOffer(updateOfferZod.parse(values)).unwrap();
  };

  const deleteOffer = async (offer: TOffer) => {
    await deleteOfferMutation(idZod.parse({ id: offer.id })).unwrap();
  };

  const getMaxOfferedPrice = (listing: TListing) => {
    return listing.offersStats.allTimeMaxOfferedPrice;
  };

  return {
    createOffer,
    updateStatusOffer,
    modifyOffer,
    deleteOffer,
    getMaxOfferedPrice,
  };
};

export default useOffers;
