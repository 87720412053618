import {
  useCreateWTBMutation,
  useCreateWTSMutation,
  useDeleteListingMutation,
  useSellWTBMutation,
  useUpdateListingInProgressMutation,
  useUpdateWTBMutation,
  useUpdateWTSMutation,
  useUpdateExpirationMutation,
  useClaimTokensMutation,
} from '@store/api/listingApi';

const useListings = () => {
  const [createWTB] = useCreateWTBMutation();

  const [createWTS] = useCreateWTSMutation();

  const [updateWTS] = useUpdateWTSMutation();

  const [updateWTB] = useUpdateWTBMutation();

  const [claimTokens] = useClaimTokensMutation();

  const [updateExpiration] = useUpdateExpirationMutation();

  const [deleteListing] = useDeleteListingMutation();

  const [sellWTB] = useSellWTBMutation();

  const [updateTransactionInProgress] = useUpdateListingInProgressMutation();

  const MAX_EXPIRE_IN_DAYS = 30;

  return {
    createWTB,
    createWTS,
    deleteListing,
    updateExpiration,
    claimTokens,
    updateWTB,
    updateWTS,
    sellWTB,
    MAX_EXPIRE_IN_DAYS,
    updateTransactionInProgress,
  };
};

export default useListings;
