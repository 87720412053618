import Moralis from 'moralis-v1';
import useUtils from '@hooks/useUtils';
import {
  TOtcContractTransfer,
  TOtcOtcContractFnParams,
} from '@typescript/TOtcTransferContract';
import { TTxResponse } from '@typescript/models/TradeTransaction.model';
import {
  OTC_TRANSFER_FEE_PERCENTAGE,
  otcTransferContract,
} from '@constants/COtcTransferContract';
import { ERC20Contract } from '@constants/CERC20Contract';
import { calculate } from '@utils/functions';
import { useLazyCheckListingStatusQuery } from '@store/api/listingApi';
import { checkListingStatusZod } from '@typescript/dtos/listing/getListings.dto';
import useERC20 from './useERC20';
import useUser from './useUser';
import useNotification from './useNotification';

const useOtcTransferContract = () => {
  const { currentUser: user } = useUser();

  const { notifyError } = useNotification();

  const { serverNetwork } = useUtils();

  const [checkListingStatus] = useLazyCheckListingStatusQuery();

  const { approve, checkAllowance } = useERC20();

  const call = async ({ functionName, params }: TOtcOtcContractFnParams) => {
    if (!serverNetwork) {
      throw Error('Server network request not initialized.');
    }

    const options = {
      abi: otcTransferContract[serverNetwork].abi,
      contractAddress: otcTransferContract[serverNetwork].address,
      functionName,
      params,
    };

    const response = await Moralis.executeFunction(options);

    return response;
  };

  const transfer = async ({
    coin,
    receiver,
    amount,
    listingId,
    tokenPrice,
    tokenAmount,
    type,
  }: TOtcContractTransfer) => {
    if (!user) {
      throw Error('No user found to make contract transfer');
    }

    if (!serverNetwork) {
      throw Error('No serverNetwork found to make contract transfer');
    }

    const amountInWei = Moralis.Units.Token(amount, 18);
    // TODO: GET OTC CONTRACT FEE from contract.
    const feeAmount = calculate(OTC_TRANSFER_FEE_PERCENTAGE, '%', +amount);
    const amountWithFee = calculate(+amount, '+', feeAmount);
    const amountWithFeeInWei = Moralis.Units.Token(amountWithFee, 18);

    const stableContractData = ERC20Contract[coin];
    const userAddress = user.ethAddress;
    const stableAddress = stableContractData[serverNetwork].address;

    const otcTransferContractAddress =
      otcTransferContract[serverNetwork].address;

    const allowance = await checkAllowance({
      coin,
      spender: otcTransferContractAddress,
      owner: userAddress,
    });

    if (+amountWithFee !== +allowance) {
      await approve({
        coin,
        spender: otcTransferContractAddress,
        amount: amountWithFeeInWei,
      });
      const newAllowance = await checkAllowance({
        coin,
        spender: otcTransferContractAddress,
        owner: userAddress,
      });

      if (+amountWithFee !== +newAllowance) {
        notifyError('Not enough contract allowance.');
        throw Error('Not enough contract allowance.');
      }
    }

    await checkListingStatus(
      checkListingStatusZod.parse({
        amount: tokenAmount,
        tokenPrice,
        id: listingId,
        type,
      }),
    ).unwrap();

    const createTxResponse = (await call({
      functionName: 'handleOTCTransaction',
      params: {
        tokenAddress: stableAddress,
        receiver,
        amount: amountInWei,
        refId: listingId,
      },
    })) as unknown as TTxResponse;

    // TODO: we can wait for the tx to finish here
    return createTxResponse;
  };

  return {
    transfer,
    call,
  };
};

export default useOtcTransferContract;
