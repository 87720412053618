import { useState } from 'react';
import * as XLSX from 'xlsx';
import { calculate, formatNr } from '@utils/functions';
import { TExcelAllocation, TExcelFee, TExcelProject } from '@typescript/TExcel';
import { TAllocation } from '@typescript/models/Allocation.model';
import { ExcelProjectColumn } from '@constants/CExcel';
import { TProject } from '@typescript/models/Project.model';
import useProject from './useProject';
import useNotification from './useNotification';

const useExcelProjectData = () => {
  const { notifyError, notifySuccess } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const { createExcelProject } = useProject();

  const createAllExcelData = async (
    project: TExcelProject,
    allocations: TExcelAllocation[],
    fee: TExcelFee,
  ) => {
    try {
      await createExcelProject({ project, allocations, fee });

      notifySuccess(`project ${project.projectName} uploaded`);
    } finally {
      setIsLoading(false);
    }
  };

  const validateExcelProject = (project: TExcelProject) => {
    const checkExists = (property: keyof TExcelProject) => {
      if (!project[property]) {
        setIsLoading(false);
        throw new Error(`Missing ${property} value in project`);
      }
    };

    checkExists('projectName');
    checkExists('tokenPrice');
    checkExists('totalInvestmentAmount');
    checkExists('totalInvestmentAmountWithFee');
    checkExists('totalTokens');
    checkExists('round');
  };

  const validateExcelFee = (fee: TExcelFee) => {
    // eslint-disable-next-line no-restricted-syntax

    const checkExists = (property: keyof TExcelFee) => {
      if (!fee[property]) {
        setIsLoading(false);
        throw new Error(`Missing ${property} value in fee`);
      }
    };

    checkExists('lvWallet');
    checkExists('receiverWallet');
    checkExists('feeInTokens');
    checkExists('feeInUsd');
  };

  const validateExcelAllocations = (allocations: TExcelAllocation[]) => {
    // eslint-disable-next-line no-restricted-syntax
    const checkExists = (
      allocation: TExcelAllocation,
      property: keyof TExcelAllocation,
    ) => {
      if (!allocation[property]) {
        setIsLoading(false);
        throw new Error(`Missing ${property} value in one allocation`);
      }
    };
    allocations.forEach((currentAllocation) => {
      checkExists(currentAllocation, 'userWallet');
      checkExists(currentAllocation, 'investmentAmountWithFee');
      checkExists(currentAllocation, 'totalTokens');
      checkExists(currentAllocation, 'percentage');
    });
  };

  const validateExcelData = (
    project: TExcelProject,
    allocations: TExcelAllocation[],
    fee: TExcelFee,
  ) => {
    validateExcelProject(project);
    validateExcelAllocations(allocations);
    validateExcelFee(fee);
  };

  const handleUploadExcel = async (excelFile: File) => {
    setIsLoading(true);
    const extension = excelFile.name.split('.')[1];
    if (extension !== 'xlsx' && extension !== 'xls') {
      notifyError('Only Excel files are allowed to be uploaded!');
      setIsLoading(false);
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const bstr = e.target?.result;
      const excel = XLSX.read(bstr, { type: 'binary' });

      // eslint-disable-next-line no-restricted-syntax
      for (const sheet of excel.SheetNames) {
        const workSheet = excel.Sheets[sheet];
        const sheetData = XLSX.utils.sheet_to_json(workSheet, {
          header: 1,
        }) as string[];

        // ? validate excel format type
        const headerProjectName = sheetData[0][ExcelProjectColumn.PROJECT_NAME];
        if (headerProjectName?.toLowerCase() !== 'project name') {
          setIsLoading(false);
          notifyError('The excel file is not in the correct format type');
          throw Error('The excel file is not in the project format type');
        }

        const LV_FEE_ROW_INDEX = sheetData.length - 2;
        const LV_FEE_ROW = sheetData[LV_FEE_ROW_INDEX];
        const PROJECT_UNIQUE_DATA_ROW = 1;

        // ? fee data
        const feeInUsd =
          +LV_FEE_ROW[ExcelProjectColumn.INVESTED_AMOUNT_WITH_FEE] || 0;
        const feeInTokens = +LV_FEE_ROW[ExcelProjectColumn.TOTAL_TOKENS] || 0;
        let feeInPercentage = +LV_FEE_ROW[ExcelProjectColumn.PERCENTAGE] || 0;
        feeInPercentage = calculate(feeInPercentage, '*', 100);
        const lvReceiverWallet = LV_FEE_ROW[ExcelProjectColumn.RECEIVER_WALLET];

        // ? set unique project data
        const projectName =
          sheetData[PROJECT_UNIQUE_DATA_ROW][ExcelProjectColumn.PROJECT_NAME];
        const lvWallet =
          sheetData[PROJECT_UNIQUE_DATA_ROW][ExcelProjectColumn.LV_WALLET];
        const tokenPrice =
          +sheetData[PROJECT_UNIQUE_DATA_ROW][ExcelProjectColumn.TOKEN_PRICE] ||
          0;
        const symbol =
          sheetData[PROJECT_UNIQUE_DATA_ROW][ExcelProjectColumn.SYMBOL];
        const round =
          sheetData[PROJECT_UNIQUE_DATA_ROW][ExcelProjectColumn.TYPE];
        const chain =
          sheetData[PROJECT_UNIQUE_DATA_ROW][ExcelProjectColumn.CHAIN];
        const tokenAddress =
          sheetData[PROJECT_UNIQUE_DATA_ROW][ExcelProjectColumn.TOKEN_ADDRESS];

        // ? total project data
        let projectTotalTokens = 0;
        let projectTotalInvestmentAmount = 0;
        let projectTotalInvestmentAmountWithFee = 0;

        // ? allocation data
        let totalProjectPercentage = 0;
        const excelAllocations: TExcelAllocation[] = [];
        for (let rowIndex = 1; rowIndex < sheetData.length - 1; rowIndex += 1) {
          const row = sheetData[rowIndex];
          const allocationSocial = row[ExcelProjectColumn.SOCIAL];
          const allocationUserWallet = row[ExcelProjectColumn.RECEIVER_WALLET];

          const allocationInvestmentAmount =
            row[ExcelProjectColumn.INVESTED_AMOUNT] || 0;
          const allocationInvestmentAmountWithFee =
            row[ExcelProjectColumn.INVESTED_AMOUNT_WITH_FEE] || 0;
          const allocationTotalTokens =
            row[ExcelProjectColumn.TOTAL_TOKENS] || 0;
          let allocationPercentage = +row[ExcelProjectColumn.PERCENTAGE] || 0;
          allocationPercentage = calculate(allocationPercentage, '*', 100);

          projectTotalTokens = calculate(
            projectTotalTokens,
            '+',
            +allocationTotalTokens,
          );

          projectTotalInvestmentAmount = calculate(
            projectTotalInvestmentAmount,
            '+',
            +allocationInvestmentAmount,
          );

          projectTotalInvestmentAmountWithFee = calculate(
            projectTotalInvestmentAmountWithFee,
            '+',
            +allocationInvestmentAmountWithFee,
          );

          totalProjectPercentage = calculate(
            totalProjectPercentage,
            '+',
            +allocationPercentage,
          );

          const excelAllocation: TExcelAllocation = {
            projectName,
            percentage: allocationPercentage,
            social: allocationSocial,
            userWallet: allocationUserWallet?.toLowerCase().trim(),
            investmentAmount: +allocationInvestmentAmount,
            investmentAmountWithFee: +allocationInvestmentAmountWithFee,
            totalTokens: +allocationTotalTokens,
          };
          excelAllocations.push(excelAllocation);
        }

        const excelProject: TExcelProject = {
          projectName,
          tokenPrice,
          totalInvestmentAmount: projectTotalInvestmentAmount,
          totalInvestmentAmountWithFee: projectTotalInvestmentAmountWithFee,
          totalTokens: projectTotalTokens,
          symbol: symbol?.toLocaleUpperCase().trim(),
          round,
          chain,
          tokenAddress,
        };

        const excelFee: TExcelFee = {
          projectName,
          lvWallet,
          receiverWallet: lvReceiverWallet,
          feeInUsd,
          feeInTokens,
          feeInPercentage,
        };

        validateExcelData(excelProject, excelAllocations, excelFee);
        if (+formatNr(totalProjectPercentage) !== 100) {
          setIsLoading(false);
          notifyError('Total project percentage is not 100%');
          throw Error('Total project percentage is not 100%');
        }

        // eslint-disable-next-line no-await-in-loop
        await createAllExcelData(excelProject, excelAllocations, excelFee);
      }
      setIsLoading(false);
    };
    reader.readAsBinaryString(excelFile);
  };

  const orderAllocationsByInvestmentAmount = (allocations: TAllocation[]) =>
    allocations.sort((a, b) =>
      a.investmentAmount < b.investmentAmount ? 1 : -1,
    );

  const handleExportProjects = (
    allocations: TAllocation[],
    projects: TProject[],
  ) => {
    // ? create the excel file
    const workbook = XLSX.utils.book_new();

    const sheetHeader = [
      'Project Name',
      'LV wallet',
      'LV Price',
      'Funds Raised',
      'Telegram / Discord username',
      'Your wallet address that will contribute and receive the tokens to',
      'Investment Amount',
      'Investment Amount with Fee',
      'Percent',
      'Total Tokens to be distributed',
      'Symbol',
      'Chain',
      'Token Address',
    ];

    projects.forEach((project) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const excelSheet: any[] = [sheetHeader];

      const projectAllocations = allocations.filter(
        (allocation) => allocation.project.id === project.id,
      );

      const orderedAllocations =
        orderAllocationsByInvestmentAmount(projectAllocations);

      const lastExcelRow = [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        project.investmentAmount,
        project.investmentAmountWithFee,
        undefined,
        project.totalTokens,
      ];

      const firstAllocation = orderedAllocations[0];
      const firstAllocationUserAddress =
        firstAllocation?.user?.ethAddress || firstAllocation.excelWalletAddress;

      if (!firstAllocationUserAddress) {
        notifyError(
          `No user address found in allocation ${firstAllocation.id}`,
        );
        throw Error(
          `No user address found in allocation ${firstAllocation.id}`,
        );
      }

      const firstExcelRow = [
        project.name,
        project.fee.lvWallet,
        project.tokenPrice,
        project.investmentAmount,
        firstAllocation.social,
        firstAllocationUserAddress,
        firstAllocation.investmentAmount || 0,
        firstAllocation.investmentAmountWithFee || 0,
        firstAllocation.percentage || 0,
        firstAllocation.totalTokens,
        project.symbol,
        project.chain,
        project.tokenAddress,
      ];

      excelSheet.push(firstExcelRow);

      for (let index = 1; index < orderedAllocations.length; index += 1) {
        const allocation = orderedAllocations[index];
        const allocationUser =
          allocation?.user?.ethAddress || allocation.excelWalletAddress;

        if (!allocationUser) {
          notifyError(`No user address found in allocation ${allocation.id}`);
          throw Error(`No user address found in allocation ${allocation.id}`);
        }

        const excelRow = [
          undefined,
          undefined,
          undefined,
          undefined,
          allocation.social,
          allocationUser,
          allocation.investmentAmount || 0,
          allocation.investmentAmountWithFee || 0,
          allocation.percentage || 0,
          allocation.totalTokens || 0,
          undefined,
          undefined,
          undefined,
          undefined,
        ];

        excelSheet.push(excelRow);
      }

      excelSheet.push(lastExcelRow);

      const sheet = XLSX.utils.aoa_to_sheet(excelSheet);
      if (project.name) {
        XLSX.utils.book_append_sheet(
          workbook,
          sheet,
          project.name.toLowerCase(),
        );
      }
    });

    const fileName =
      projects.length === 1
        ? `${projects[0].name}-export.xlsx`
        : 'projects-export.xlsx';
    XLSX.writeFile(workbook, fileName);
  };

  return {
    handleUploadExcel,
    isLoading,
    handleExportProjects,
  };
};

export default useExcelProjectData;
