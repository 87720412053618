import { useSnackbar } from 'notistack';
import {
  NotifySuccessSettings,
  NotifyErrorSettings,
  NotifyWarningSettings,
  NotifyInfoSettings,
} from '@constants/CNotifySettings';

const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifySuccess = (text: string) =>
    enqueueSnackbar(text, NotifySuccessSettings);

  const notifyError = (text: string) =>
    enqueueSnackbar(text, NotifyErrorSettings);

  const notifyWarning = (text: string) =>
    enqueueSnackbar(text, NotifyWarningSettings);

  const notifyInfo = (text: string) =>
    enqueueSnackbar(text, NotifyInfoSettings);

  return {
    notifyError,
    notifySuccess,
    notifyWarning,
    notifyInfo,
  };
};

export default useNotification;
